import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useAccount, useCart } from '@frontastic-engbers/lib';
import { Address as AddressType } from '@frontastic-engbers/types/account/Address';
import { CheckoutData, CheckoutErrorsType, CustomerData, CustomerOrigin, ForbiddenFields } from '@frontastic-engbers/types/engbers-custom';
import { Address } from '../../address';
import { mapToFormStructure } from '../../utils/mapFormData';
interface IBillingAddressSection {
  data: CheckoutData;
  setCheckoutData: Dispatch<SetStateAction<CheckoutData>>;
  setBillingAddressPrefilled: Dispatch<SetStateAction<boolean>>;
  updateFormInput: (propName: string, newValue: string | AddressType | CustomerData) => void;
  labels: {
    [name: string]: string;
  };
  customerOrigin: CustomerOrigin;
  forbiddenFields: ForbiddenFields;
  setCheckoutErrors: React.Dispatch<React.SetStateAction<CheckoutErrorsType>>;
}
export const BillingAddress: React.FC<IBillingAddressSection> = ({
  data,
  setCheckoutData,
  setBillingAddressPrefilled,
  updateFormInput,
  labels,
  customerOrigin,
  forbiddenFields,
  setCheckoutErrors
}) => {
  const router = useRouter();
  const {
    loggedIn,
    account,
    updateAccountWithWebservice,
    webserviceAccountUpdateFailed,
    webserviceUpdated
  } = useAccount();
  const isGuest = !loggedIn && router.query.isGuest === 'true';
  const isRedirectPayment = router.query.paymentId || router.query.hostedCheckoutId;
  const {
    data: cart
  } = useCart();
  const addressPrefilled = useRef<boolean>(false);
  const [readyToValidate, setReadyToValidate] = useState<boolean>(false);

  // prefill form data based on migration login
  useEffect(() => {
    const registerFormState = sessionStorage.getItem('registerFormState');
    if (registerFormState) {
      const initialState = JSON.parse(registerFormState);
      setCheckoutData(prevState => ({
        ...prevState,
        billingAddress: {
          salutation: initialState.salutation,
          firstName: initialState.firstName,
          lastName: initialState.lastName,
          streetName: initialState.addressStreetName,
          streetNumber: initialState.addressStreetNumber,
          city: initialState.addressCity,
          country: initialState.addressCountry,
          postalCode: initialState.addressPostalCode,
          ...(initialState.mobilePhoneNumber && {
            mobile: initialState.mobilePhoneNumber
          }),
          ...(initialState.phoneNumber && {
            phone: initialState.phoneNumber
          })
        },
        customerData: {
          ...data.customerData,
          email: initialState.email,
          cardId: initialState.cardId,
          isMigrationUser: true,
          birthdayDay: initialState.birthdayDay,
          birthdayMonth: initialState.birthdayMonth,
          birthdayYear: initialState.birthdayYear,
          ...(initialState.title && {
            title: initialState.title
          })
        }
      }));
      setReadyToValidate(true);
      sessionStorage.removeItem('registerFormState');
    }
  }, [typeof sessionStorage !== 'undefined' ? sessionStorage.registerFormState : undefined]);
  useEffect(() => {
    if (loggedIn) {
      const getAccount = async () => {
        await updateAccountWithWebservice(account, true);
      };
      getAccount();
    }
  }, [loggedIn]);

  // prefill form data based on cart or account data
  useEffect(() => {
    if (!cart || addressPrefilled.current || isGuest && !isRedirectPayment || account && !webserviceUpdated && !webserviceAccountUpdateFailed) {
      return;
    }
    if (loggedIn) {
      const address = account?.addresses?.find(address => address.isDefaultBillingAddress);
      if (address) {
        setCheckoutData(prevState => ({
          ...prevState,
          billingAddress: {
            salutation: address.salutation && address.salutation !== '' ? address.salutation : account.salutation,
            firstName: address.firstName && address.firstName !== '' ? address.firstName : account.firstName,
            lastName: address.lastName && address.lastName !== '' ? address.lastName : account.lastName,
            streetName: address.streetName,
            streetNumber: address.streetNumber,
            city: address.city,
            country: address.country,
            postalCode: address.postalCode
          },
          customerData: {
            ...data.customerData,
            email: account.email,
            cardId: account.cardId,
            birthdayDay: account.birthday.getDate().toString().padStart(2, '0'),
            birthdayMonth: (account.birthday.getMonth() + 1).toString().padStart(2, '0'),
            birthdayYear: account.birthday.getFullYear().toString()
          },
          solvency: {
            date: account.custom?.fields.SolvencyQueryDate,
            result: account.custom?.fields.SolvencyQuery,
            accepted: account.custom?.fields.SolvencyQueryAccepted
          }
        }));
        setBillingAddressPrefilled(true);
        setReadyToValidate(true);
        return;
      }
    }
    const defaultData = mapToFormStructure(cart, data);
    if (defaultData) {
      setCheckoutData(prevState => ({
        ...prevState,
        ...defaultData
      }));
      setReadyToValidate(true);
    }
    addressPrefilled.current = true;
  }, [cart, loggedIn, webserviceUpdated]);
  return <Address data={data} updateFormInput={updateFormInput} labels={labels} customerOrigin={customerOrigin} forbiddenFields={forbiddenFields} isShipping={false} setCheckoutErrors={setCheckoutErrors} loggedIn={loggedIn} readyToValidate={readyToValidate} data-sentry-element="Address" data-sentry-component="BillingAddress" data-sentry-source-file="index.tsx" />;
};