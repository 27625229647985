import React, { useMemo } from 'react';
import { getActiveShops } from '@frontastic-engbers/lib';
import { TBreadcrumb } from '@frontastic-engbers/types/engbers-custom';
import Link from 'next/link';
import styles from './breadcrumb.module.scss';
import classNames from 'classnames';
interface IBreadcrumb {
  breadCrumbs: TBreadcrumb[];
  paddingBottomInPix?: number;
  isLandingPage?: boolean;
}
export const Breadcrumb: React.FC<IBreadcrumb> = ({
  breadCrumbs,
  paddingBottomInPix = null,
  isLandingPage = false
}) => {
  const activeShops = getActiveShops();
  const shop = Object.keys(activeShops).find(key => activeShops[key]);
  const getCurrentShop = (activeShop: string) => ({
    'engbers-xxl': 'XXL',
    'engbers-germany': 'ENGBERS GERMANY',
    'emilio-adani': 'emilio adani'
  })[activeShop] || 'engbers';
  const breadCrumbsFixedNames = useMemo(() => {
    return breadCrumbs.map(breadcrumb => {
      return {
        ...breadcrumb,
        name: breadcrumb.name === 'engbers XXL' ? 'XXL' : breadcrumb.name
      };
    });
  }, [breadCrumbs]);
  if (isLandingPage) {
    return <ul style={{
      paddingBottom: `${paddingBottomInPix}px`
    }} itemScope itemType="http://schema.org/BreadcrumbList">
        <li className={styles.listElement} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <Link key={getCurrentShop(shop)} href="/">
            <a itemProp="item">
              <span itemProp="name">{getCurrentShop(shop)}</span>
            </a>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        {breadCrumbsFixedNames && breadCrumbsFixedNames.map((breadcrumb, index) => !breadcrumb.active ? <li className={styles.listElement} key={breadcrumb.name} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                <Link key={breadcrumb.name} href={breadcrumb.link}>
                  <a itemProp="item">
                    <span itemProp="name">{breadcrumb.name}</span>
                  </a>
                </Link>
                <meta itemProp="position" content={`${index + 2}`} />
              </li> : <li className={classNames(styles.active, styles.listElement)} key={breadcrumb.name} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                <a itemProp="item">
                  <span itemProp="name">{breadcrumb.name}</span>
                </a>
                <meta itemProp="position" content={`${index + 2}`} />
              </li>)}
      </ul>;
  }
  return <ul style={{
    paddingBottom: `${paddingBottomInPix}px`
  }} itemScope itemType="http://schema.org/BreadcrumbList" data-sentry-component="Breadcrumb" data-sentry-source-file="index.tsx">
      {breadCrumbsFixedNames && breadCrumbsFixedNames.map((breadcrumb, index) => !breadcrumb.active ? <li className={styles.listElement} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" key={breadcrumb.name}>
              <Link key={breadcrumb.name} href={breadcrumb.link}>
                <a itemProp="item">
                  <span itemProp="name">{breadcrumb.name === 'Default' ? getCurrentShop(shop) : breadcrumb.name}</span>
                </a>
              </Link>
              <meta itemProp="position" content={`${index + 1}`} />
            </li> : <li className={classNames(styles.active, styles.listElement)} key={breadcrumb.name} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
              <a itemProp="item">
                <span itemProp="name">{breadcrumb.name}</span>
              </a>
              <meta itemProp="position" content={`${index + 1}`} />
            </li>)}
    </ul>;
};