import React, { useEffect, useMemo, useState } from 'react';
import { useState as useGlobalState } from 'state-pool';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { GlobalStateContext, useAccount } from '@frontastic-engbers/lib';
import { useCart } from '@frontastic-engbers/lib/provider';
import { UseCart } from '@frontastic-engbers/lib/provider/frontastic/UseCart';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { mapCosts } from '@frontastic-engbers/helpers/utils/mapCosts';
import { cartHasFreeShippingItemsOnly } from '@frontastic-engbers/helpers/utils/cartHasFreeShippingItemsOnly';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { IconCustom, LoadingSpinner } from '@engbers/components';
import { FlyoutCartLineItems } from './cart-line-items';
import { FlyoutCartButtons } from './cart-buttons';
import { PopUp } from './PopUp';
import Price from '../price';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { EcommerceDataLayerItem } from '@frontastic-engbers/types/tagmanager/EcommerceDataLayerItem';
import styles from './flyout-cart.module.scss';
import { joinDiscounts } from '@frontastic-engbers/helpers/dataLayerHelper/couponCodeDataHelper';
interface IFlyoutCart {
  headline: string;
  toCheckoutBtnLabel: string;
  voucherBtnLabel: string;
  toBasketBtnLabel: string;
  shippingCostsNote: string;
  voucherPlaceholder: string;
  redeemVoucherBtnLabel: string;
  backBtnLabel: string;
  headlinePopUp: string;
  toCartBtn: string;
  continueShoppingBtn: string;
  headlineAlternativeProduct: string;
  headlineAlternativeOutfit: string;
  continueShoppingLabel: string;
  toOutfitLabel: string;
  voucherRedeemedLabel: string;
  voucherInvalidLabel: string;
  climateNeutralShippingLabel: string;
  fallbackShippingCostsDe: number;
  fallbackShippingCostsEu: number;
}
export const FlyoutCart: React.FC<IFlyoutCart> = ({
  headline,
  toCheckoutBtnLabel,
  voucherBtnLabel,
  toBasketBtnLabel,
  shippingCostsNote,
  voucherPlaceholder,
  redeemVoucherBtnLabel,
  backBtnLabel,
  headlinePopUp,
  toCartBtn,
  continueShoppingBtn,
  headlineAlternativeProduct,
  headlineAlternativeOutfit,
  continueShoppingLabel,
  toOutfitLabel,
  voucherRedeemedLabel,
  voucherInvalidLabel,
  climateNeutralShippingLabel,
  fallbackShippingCostsDe,
  fallbackShippingCostsEu
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1024
  });
  const {
    pushModal,
    removeModal
  } = useModalActions();
  const [{
    isFlyoutCartOpen,
    flyoutAlternativeProductsConfig
  }, setGlobalState] = useGlobalState(GlobalStateContext);
  const {
    data: cart,
    addItem,
    removeItem,
    updateItem,
    redeemDiscountCode
  } = useCart();
  const {
    account,
    loggedIn
  } = useAccount();
  const {
    formatMessage
  } = useFormat({
    name: 'cart'
  });
  const {
    formatMessage: formatCheckoutMessage
  } = useFormat({
    name: 'checkout'
  });
  const updateGlobalState = (isFlyoutCartOpen: boolean) => setGlobalState({
    isFlyoutCartOpen,
    flyoutAlternativeProductsConfig: undefined
  });
  const handleUpdate = async (fn: () => Promise<boolean>) => {
    setLoading(true);
    setGlobalState(state => ({
      ...state,
      flyoutAlternativeProductsConfig: undefined
    }));
    const continueLoading = await fn();
    setLoading(continueLoading);
  };
  const costs = useMemo(() => mapCosts(cart, loggedIn ? cart?.shippingAddress?.country || account?.addresses[0]?.country : undefined), [cart]);
  const fallbackShippingCostsPrice: number = cart?.shippingAddress?.country?.toLowerCase() === 'de' || !cart?.shippingAddress && account?.addresses[0]?.country?.toLowerCase() === 'de' ? fallbackShippingCostsDe : fallbackShippingCostsEu;
  const updateItemQuantity: UseCart['updateItem'] = (lineItemId, newQuantity) => handleUpdate(async () => {
    await updateItem(lineItemId, newQuantity);
    return false;
  });
  const updateItemSize: UseCart['addItem'] = (variant, quantity, shouldMutate) => handleUpdate(async () => {
    await addItem(variant, quantity, shouldMutate);
    return !shouldMutate;
  });
  const deleteItem: UseCart['removeItem'] = lineItemId => handleUpdate(async () => {
    await removeItem(lineItemId);
    return false;
  });
  const getLineItemsCount = (): number => cart.lineItems.reduce((accumulator, lineItem) => accumulator + lineItem.count, 0);
  useEffect(() => {
    if (cart?.lineItems) {
      setLoading(false);
    }
  }, [cart]);
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  useEffect(() => {
    if (isFlyoutCartOpen && isMinWidthMedium) {
      pushModal({
        title: '',
        id: 'mobile-added-to-cart-modal',
        content: <PopUp headlinePopUp={headlinePopUp} toCartBtn={toCartBtn} continueShoppingBtn={continueShoppingBtn} removeModal={removeModal} />,
        canCloseByBackdropClick: true,
        hasCloseButton: false
      });
      updateGlobalState(false);
    }
    if (!isMinWidthMedium) {
      removeModal();
    }
  }, [isFlyoutCartOpen, isMinWidthMedium]);
  useEffect(() => {
    if (isFlyoutCartOpen) {
      pushDatalayer('view_cart');
    }
  }, [isFlyoutCartOpen]);
  const pushDatalayer = event => {
    const datalayerItems: EcommerceDataLayerItem[] = cart?.lineItems?.map((lineItem: LineItem, index) => ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true));
    const datalayerCouponCodes = joinDiscounts(cart?.discountCodes, cart?.customLineItems);
    const lineItemsTotalValueMoney = {
      fractionDigits: cart.sum.fractionDigits ?? 2,
      centAmount: cart.lineItems.reduce((price, lineItem) => price + (lineItem.variant?.discountedPrice?.centAmount ?? lineItem.variant?.price?.centAmount ?? 0) * lineItem.count, 0),
      currency: cart.sum.currencyCode ?? 'EUR'
    };
    new TagManager().ecommerceEvent(event, datalayerItems, lineItemsTotalValueMoney, datalayerCouponCodes).executePush();
  };
  return !isMinWidthMedium && <>
        <div className={classnames(styles.flyoutCartOverlay, isFlyoutCartOpen ? styles.isFlyoutCartOverlayOpen : undefined)} onClick={() => updateGlobalState(false)}></div>

        <div className={classnames(styles.flyoutCart, isFlyoutCartOpen ? styles.isFlyoutCartOpen : undefined)}>
          <LoadingSpinner isLoading={loading} className={styles.spinnerWrap} />
          <div className={styles.flyoutCartHeaderWrap}>
            <div className={styles.flyoutCartHeader}>
              <strong>{headline}</strong>
              <div className={styles.flyoutCartHeader}>
                {cart?.lineItems ? getLineItemsCount() : 0}
                <span style={{
              padding: '0 4px'
            }}>
                  {formatMessage({
                id: 'article',
                defaultMessage: 'Artikel'
              })}
                </span>
                | <Price price={cart?.sum || {}} className={styles.flyoutCartHeaderPrice} showCurrencySymbol />
              </div>
            </div>
            <button onClick={() => updateGlobalState(false)}>
              <IconCustom width={18} color={'primary'} icon="Close" />
            </button>
          </div>

          <div className={styles.flyoutCartBody}>
            <FlyoutCartLineItems addItem={updateItemSize} removeItem={deleteItem} updateItem={updateItemQuantity} setLoading={setLoading} updateGlobalState={updateGlobalState} alternativeProductsConfig={flyoutAlternativeProductsConfig} toCheckoutBtnLabel={toCheckoutBtnLabel} toBasketBtnLabel={toBasketBtnLabel} headlineAlternativeProduct={headlineAlternativeProduct} headlineAlternativeOutfit={headlineAlternativeOutfit} continueShoppingLabel={continueShoppingLabel} toOutfitLabel={toOutfitLabel} />
          </div>

          <div className={classnames(styles.flyoutCartFooter, {
        [styles.flyoutCartFooterHigher]: climateNeutralShippingLabel && (costs.shipping || fallbackShippingCostsPrice)
      })}>
            <div className={styles.flyoutCartSumSection}>
              {costs.subtotal.centAmount && <div className="flex items-center justify-between">
                  {formatMessage({
              id: 'subtotal',
              defaultMessage: 'Zwischensumme'
            })}
                  <Price price={costs.subtotal} className={styles.flyoutCartHeaderPrice} showCurrencySymbol />
                </div>}
              {!cartHasFreeShippingItemsOnly(cart) && !!costs.shipping.centAmount && climateNeutralShippingLabel && <div className="flex items-center justify-between">
                  <span>{climateNeutralShippingLabel}</span>
                  {costs.shipping.centAmount === 0 && <span className="text-success text-sm-bold">
                      {formatCheckoutMessage({
                id: 'freeShipping',
                defaultMessage: 'kostenlos'
              })}
                    </span>}
                  {!!costs.shipping.centAmount && <Price price={costs.shipping || {
              centAmount: fallbackShippingCostsPrice,
              currencyCode: costs.total.currencyCode,
              fractionDigits: 2
            }} className={styles.flyoutCartHeaderPrice} showCurrencySymbol />}
                </div>}
              <div className={styles.flyoutCartSumSectionPrice}>
                <strong>
                  {formatMessage({
                id: 'totalSum',
                defaultMessage: 'Gesamtsumme'
              })}
                </strong>
                <strong>
                  <Price price={loggedIn && !costs.shipping && fallbackShippingCostsPrice ? {
                centAmount: costs.total.centAmount + fallbackShippingCostsPrice,
                currencyCode: costs.total.currencyCode,
                fractionDigits: 2
              } : costs.total ?? {}} className={styles.flyoutCartHeaderPrice} isBold showCurrencySymbol />
                </strong>
              </div>
              {costs.savedPrice && costs.savedPrice.centAmount > 0 && <div className={styles.flyoutCartSavedPriceSection}>
                  <span className={styles.savedPrice}>
                    {formatCheckoutMessage({
                id: 'youSaved',
                defaultMessage: 'Sie sparen'
              })}
                  </span>
                  <Price price={costs.savedPrice || {}} className={styles.savedPrice} showCurrencySymbol />
                </div>}
              <div>{shippingCostsNote}</div>
            </div>
            <FlyoutCartButtons toCheckoutBtnLabel={toCheckoutBtnLabel} voucherBtnLabel={voucherBtnLabel} toBasketBtnLabel={toBasketBtnLabel} voucherPlaceholder={voucherPlaceholder} redeemVoucherBtnLabel={redeemVoucherBtnLabel} backBtnLabel={backBtnLabel} updateGlobalState={updateGlobalState} redeemDiscountCode={redeemDiscountCode} isEmptyCart={!cart?.lineItems?.length} voucherRedeemedLabel={voucherRedeemedLabel} voucherInvalidLabel={voucherInvalidLabel} setLoading={setLoading} pushDatalayer={pushDatalayer} />
          </div>
        </div>
      </>;
};