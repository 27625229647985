import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';
import { useInfiniteHits } from 'react-instantsearch';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { InfiniteHits } from './components/infinite-hits';
import { CustomConfigure } from './components/custom-configure';
import MobileFacets from './components/mobile-facets';
import DesktopFacets from './components/desktop-facets';
import { PageFolder } from '@frontastic-engbers/lib/lib/types';
import { MediaType, PageFolderTree, ProductListMedia } from '@frontastic-engbers/types/engbers-custom';
import { CategorySlider } from './components/category-slider';
import { useInstantSearch } from 'react-instantsearch-core';
import { Breadcrumb } from '@engbers/components/online-shops/breadcrumb';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { PlainSearchParameters } from 'algoliasearch-helper';
import LoadingPlaceholder from '@frontastic-engbers/lib/lib/loading-placeholder';
const CurrentRefinements = dynamic(() => import('./components/current-refinements').then(module => module.CurrentRefinements), {
  loading: () => <LoadingPlaceholder height="40px" />
});
const Sorting = dynamic(() => import('./components/sorting').then(module => module.Sorting), {
  loading: () => <LoadingPlaceholder height="40px" />
});
interface IProductList {
  searchQuery?: string;
  filterQuery?: string;
  contextQuery?: string[];
  progressBarText?: string;
  progressBarEndText?: string;
  pageFolder: PageFolder;
  indexName: string;
  sortingLabel?: string;
  filterButtonLabel?: string;
  showProductsLabel?: string;
  isOutfit?: boolean;
  mediaConfig?: ProductListMedia;
  categoryId: string;
  categoryName: string;
  navTree: PageFolderTree;
  showOutfitSlider: boolean;
  outfitSliderHeadline: string;
  outfitSliderCount: number;
  outfitSliderRowNumber: number;
  isEnGermanyProductList?: boolean;
  enGermanySubheadline?: string;
  enGermanyHeadline?: string;
  isEmilio?: boolean;
  fallbackImage?: MediaType;
}
const ProductList: React.FC<IProductList> = ({
  searchQuery,
  filterQuery,
  contextQuery,
  progressBarText,
  progressBarEndText,
  pageFolder,
  indexName,
  sortingLabel,
  filterButtonLabel,
  showProductsLabel,
  isOutfit = false,
  mediaConfig,
  categoryId,
  categoryName,
  navTree,
  showOutfitSlider,
  outfitSliderHeadline,
  outfitSliderCount,
  outfitSliderRowNumber,
  isEnGermanyProductList,
  enGermanyHeadline,
  enGermanySubheadline,
  isEmilio,
  fallbackImage
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<PlainSearchParameters>(undefined);
  const {
    results
  } = useInfiniteHits();
  const {
    uiState
  } = useInstantSearch();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const isMobile = useMediaQuery({
    maxWidth: 1024
  });
  const hitsPerPage = 48;
  const getInstantSearchConfiguration = (hitsPerPage = 48): Record<string, string | number | string[]> => {
    const stateConfig = uiState[indexName]?.configure;
    return {
      query: searchQuery || stateConfig?.query || undefined,
      filters: filterQuery || stateConfig?.filters || undefined,
      hitsPerPage,
      ruleContexts: contextQuery || []
    };
  };
  useEffect(() => {
    if (isMobile !== undefined) {
      setIsInitialized(true);
    }
  }, [isMobile]);
  useEffect(() => {
    localStorage.setItem('productGroup', 'false');
    localStorage.setItem('breadcrumb', JSON.stringify(pageFolder.customBreadcrumb));
  }, [pageFolder]);
  useEffect(() => {
    if (searchQuery && results.nbSortedHits) {
      const econdaDataLayer = JSON.parse(sessionStorage.getItem('econda_data_layer'));
      if (econdaDataLayer) {
        econdaDataLayer.ecContent = 'Suche/Ergebnisse';
        econdaDataLayer.ecCategory = undefined;
        econdaDataLayer.ecSearchPhrase = searchQuery;
        econdaDataLayer.ecSearchResults = results.nbSortedHits;
        new TagManager().updateEcondaDataEvent(econdaDataLayer).executePush();
      }
    }
  }, [searchQuery, results]);
  useEffect(() => {
    setSearchParams(getInstantSearchConfiguration(hitsPerPage));
  }, [filterQuery]);
  return <>
      {searchParams && <CustomConfigure params={searchParams} />}

      {searchQuery && <div className="text-left lg:text-center">
          <h1 className="mb-3 mt-5 text-xl uppercase lg:mt-3">
            {formatMessage({
          id: 'searchResultsLabel',
          defaultMessage: 'Suchergebnis :'
        })}{' '}
            {results.nbSortedHits}
          </h1>
        </div>}

      {!searchQuery && !isEnGermanyProductList && <div className="mb-3 flex items-center justify-between">
          {!isMobile && pageFolder.customBreadcrumb && <Breadcrumb breadCrumbs={pageFolder.customBreadcrumb!} />}
        </div>}

      <CategorySlider navTree={navTree} pageFolder={pageFolder} data-sentry-element="CategorySlider" data-sentry-source-file="index.tsx" />

      {isInitialized && !isOutfit && !isEnGermanyProductList && <>
          <MobileFacets indexName={indexName} sortingLabel={sortingLabel} filterButtonLabel={filterButtonLabel} showProductsLabel={showProductsLabel} />
          <DesktopFacets />

          <div className="mb-3 flex items-center justify-between">
            <CurrentRefinements />
            {!isMobile && <Sorting resultsIndexName={results.index} sortingLabel={sortingLabel} indexName={indexName} isMobile={false} />}
          </div>
        </>}

      <InfiniteHits hitsPerPage={hitsPerPage} progressBarText={progressBarText} progressBarEndText={progressBarEndText} isOutfit={isOutfit} mediaConfig={mediaConfig} categoryId={categoryId} categoryName={categoryName} indexName={indexName} filterQuery={filterQuery} showOutfitSlider={showOutfitSlider} outfitSliderHeadline={outfitSliderHeadline} outfitSliderCount={outfitSliderCount} outfitSliderRowNumber={outfitSliderRowNumber} isEnGermanyProductList={isEnGermanyProductList} enGermanySubheadline={enGermanySubheadline} enGermanyHeadline={enGermanyHeadline} searchQuery={searchQuery} isEmilio={isEmilio} fallbackImage={fallbackImage} data-sentry-element="InfiniteHits" data-sentry-source-file="index.tsx" />
    </>;
};
export default ProductList;