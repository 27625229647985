import React from 'react';
import classnames from 'classnames';
import { RefusedGift } from '@frontastic-engbers/types/cart/RefusedGift';
import { Money } from '@frontastic-engbers/types/product/Money';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { Image, Link } from '@engbers/components';
import Price from '@engbers/components/online-shops/price';
import cartStyles from './cart.module.scss';
import lineItemStyles from './line-item.module.scss';
import { resizeImage } from '@frontastic-engbers/helpers/utils/meleven';
interface Props {
  refusedGift: RefusedGift;
  undoRefuse: (refusedGift: RefusedGift) => Promise<void>;
  isCheckout: boolean;
}
const RefusedGiftItem = ({
  refusedGift,
  undoRefuse,
  isCheckout
}: Props) => {
  const giftDiscount = refusedGift.discount;
  const giftItem = refusedGift.item;
  const {
    formatMessage
  } = useFormat({
    name: 'cart'
  });
  if (!giftItem?.variant) {
    return null;
  }
  const giftItemColor = giftItem.variant.attributes?.ColorName || giftItem.variant.attributes?.BaseColor;
  const discountLabel = giftDiscount.description || giftDiscount.name;
  const savedPrice: Money = giftItem.totalPrice && {
    centAmount: giftItem.variant.price.centAmount - giftItem.totalPrice.centAmount
  };
  return <div className={classnames(cartStyles.orderOverviewHeadlines, lineItemStyles.itemWrap, lineItemStyles.refusedItemWrap, {
    [lineItemStyles.checkoutItemWrap]: isCheckout
  })} data-sentry-component="RefusedGiftItem" data-sentry-source-file="refusedGiftItem.tsx">
      <div className={classnames(lineItemStyles.itemImage, {
      [lineItemStyles.checkoutItemImage]: isCheckout
    })}>
        {giftItem.variant.images && <Link href={giftItem._url}>
            <Image src={resizeImage(giftItem.variant.images[0], 135, 180)} alt={giftItem.name} title={giftItem.name} width={135} />
          </Link>}
      </div>
      <div className={classnames(cartStyles.productOverviewInfoWrap, {
      [cartStyles.checkoutProductOverviewInfoWrap]: isCheckout
    })}>
        <div className={classnames(lineItemStyles.itemInfo, {
        [lineItemStyles.checkoutItemInfo]: isCheckout
      })}>
          {discountLabel && <div className={classnames(lineItemStyles.itemDiscountDescriptionWrap)}>
              <span key={giftDiscount.name} className={lineItemStyles.itemDiscountDescription}>
                {discountLabel}
              </span>
            </div>}

          <Link href={giftItem._url} data-sentry-element="Link" data-sentry-source-file="refusedGiftItem.tsx">{giftItem.name}</Link>

          <div>
            {formatMessage({
            id: 'articleNumber',
            defaultMessage: 'Art. Nr.'
          })}{' '}
            {getSKUParts(giftItem.variant.sku).key}
          </div>
          {giftItemColor && <div>
              {formatMessage({
            id: 'color',
            defaultMessage: 'Farbe'
          })}
              : {giftItemColor}
            </div>}
        </div>

        <div className={lineItemStyles.itemQuantity}>
          <span>
            {formatMessage({
            id: 'quantity',
            defaultMessage: 'Anzahl'
          })}
            : 1
          </span>
        </div>

        <div className={lineItemStyles.itemPrice}>
          <div>
            <Price price={giftItem.totalPrice} discountedPrice={giftItem.discountedPrice} count={giftItem.count} className={classnames(lineItemStyles.itemPriceInfo, {
            [lineItemStyles.checkoutPriceInfo]: isCheckout
          })} isBold showCurrencySymbol data-sentry-element="Price" data-sentry-source-file="refusedGiftItem.tsx" />

            {savedPrice?.centAmount > 0 && !isCheckout && <div className={lineItemStyles.itemPriceSaved}>
                <strong>
                  {formatMessage({
                id: 'youSaved',
                defaultMessage: 'Sie sparen: '
              })}
                </strong>
                <Price price={savedPrice} className={lineItemStyles.itemPriceInfo} isBold showCurrencySymbol />
              </div>}
          </div>

          <button onClick={() => undoRefuse(refusedGift)} className={lineItemStyles.itemDelete}>
            <span className={lineItemStyles.itemDeleteIcon}>+</span>
            {formatMessage({
            id: 'addItem',
            defaultMessage: 'Artikel hinzufügen'
          })}
          </button>
        </div>
      </div>
    </div>;
};
export default RefusedGiftItem;