import { CartDetails } from '@frontastic-engbers/lib/actions/cart';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { CheckoutData, CustomerOrigin } from '@frontastic-engbers/types/engbers-custom';
import { getSelectedShippingAddress } from '@frontastic-engbers/helpers/utils/getShippingInfo';

export const mapToCartStructure = (data: CheckoutData, customerOrigin: CustomerOrigin): CartDetails => {
  const shippingAddress = getSelectedShippingAddress(data);

  if (shippingAddress && (data.shippingMethod === 'packStation' || data.shippingMethod === 'store')) {
    shippingAddress.salutation = data.billingAddress.salutation;
    shippingAddress.firstName = data.billingAddress.firstName;
    shippingAddress.lastName = data.billingAddress.lastName;
    shippingAddress.custom = {
      type: {
        typeId: 'type',
        key: 'address-additional-information-type',
      },
      fields: {
        type: data.shippingMethod === 'packStation' ? 'dhl' : 'store',
      },
    };
  }

  const billingAddress = {
    ...data.billingAddress,
    ...(customerOrigin.isGuest && {
      custom: {
        type: {
          typeId: 'type',
          key: 'address-additional-information-type',
        },
        fields: {
          dateOfBirth:
            data.customerData.birthdayYear +
            '-' +
            data.customerData.birthdayMonth +
            '-' +
            data.customerData.birthdayDay,
        },
      },
    }),
  };

  return {
    account: {
      email: data.customerData.email,
    },
    shipping: {
      addressId: '1st-shipping-address',
      ...shippingAddress,
    },
    billing: billingAddress,
    comment: data.commentOnOrder,
    shippingType: data.shippingMethod,
  };
};

export const mapToFormStructure = (cartData: Cart, data: CheckoutData) => {
  if (!cartData?.billingAddress?.country || !cartData.email) {
    return;
  }

  const birthday = cartData.billingAddress.dateOfBirth?.split('-');

  const mappedData: CheckoutData = {
    ...data,
    billingAddress: {
      salutation: cartData.billingAddress.salutation,
      firstName: cartData.billingAddress.firstName,
      lastName: cartData.billingAddress.lastName,
      streetName: cartData.billingAddress.streetName,
      streetNumber: cartData.billingAddress.streetNumber,
      postalCode: cartData.billingAddress.postalCode,
      city: cartData.billingAddress.city,
      country: cartData.billingAddress.country,
    },
    customerData: {
      ...data.customerData,
      email: cartData.email,
      emailRepeat: cartData.email,
      ...(birthday &&
        birthday.length === 3 && {
        birthdayYear: birthday[0],
        birthdayMonth: birthday[1],
        birthdayDay: birthday[2],
      }),
    },
    commentOnOrder: cartData.comment ?? '',
  };

  return mappedData;
};
