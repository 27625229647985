import React from 'react';
import { useRouter } from 'next/router';
import { useImageSEO } from './hooks/useImageSEO';
import { Product } from '@frontastic-engbers/types/product/Product';
import { CustomMarkdown } from '@engbers/components';
import { convertImageToWebp } from '@frontastic-engbers/helpers/utils/meleven';
type Props = {
  projectId: string;
  structuredData: Record<string, any>;
  siteUrl: string;
};
export const StructuredData: React.FC<Props> = ({
  projectId,
  structuredData,
  siteUrl
}) => {
  const router = useRouter();
  const {
    getTitle
  } = useImageSEO();
  if (!structuredData) {
    return null;
  }
  return <div className="hidden" data-sentry-component="StructuredData" data-sentry-source-file="structuredData.tsx">
      <div itemScope itemType="https://schema.org/Organization">
        <span itemProp="name">{structuredData.name}</span>
        <link itemProp="url" href={siteUrl} />
        <span itemProp="email">{structuredData.email}</span>
        <span itemProp="telephone">{structuredData.telephone}</span>
        <span itemProp="description">{structuredData.description}</span>
        {structuredData.logo?.media?.file && <img itemProp="logo" src={convertImageToWebp(structuredData.logo.media?.file)} alt={getTitle(structuredData.logo)} title={getTitle(structuredData.logo)} loading="lazy" />}
        <CustomMarkdown text={structuredData.sameAs} data-sentry-element="CustomMarkdown" data-sentry-source-file="structuredData.tsx" />
        {router.asPath !== '/' && <div itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
            <span itemProp="bestRating">5.00</span>
            <span itemProp="ratingValue">{structuredData.aggregateRating}</span>
            <span itemProp="ratingCount">{structuredData.ratingCount}</span>
            <span itemProp="itemReviewed">{`${projectId === 'ea' ? 'emilioadani' : 'engbers'}.com`}</span>
          </div>}
      </div>
    </div>;
};
type PDPProps = {
  product: Product;
  siteUrl: string;
};
export const StructuredDataPDP: React.FC<PDPProps> = ({
  product,
  siteUrl
}) => {
  return <div className="hidden" itemScope itemType="https://schema.org/Product" data-sentry-component="StructuredDataPDP" data-sentry-source-file="structuredData.tsx">
      {product.variants[0]?.images?.[0] && <meta itemProp="image" content={product.variants[0].images[0]} />}
      <meta itemProp="name" content={product.name} data-sentry-element="meta" data-sentry-source-file="structuredData.tsx" />
      <meta itemProp="mpn" content={product.productId} data-sentry-element="meta" data-sentry-source-file="structuredData.tsx" />
      <meta itemProp="sku" content={product.productId} data-sentry-element="meta" data-sentry-source-file="structuredData.tsx" />
      {product.attributes?.DesignerName && <meta itemProp="brand" content={product.attributes?.DesignerName} />}
      <div itemProp="description">{product.description}</div>
      <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
        <link itemProp="url" href={siteUrl + product.url} />
        {product.variants[0]?.price?.centAmount && <meta itemProp="price" content={`${product.variants[0].price.centAmount / 100}`} />}
        <meta itemProp="priceCurrency" content={product.variants[0]?.price?.currencyCode ?? 'EUR'} data-sentry-element="meta" data-sentry-source-file="structuredData.tsx" />
        <link itemProp="itemCondition" href="https://schema.org/NewCondition" />
        <link itemProp="availability" href={product.variants.every(v => v.isInStock === false) ? 'https://schema.org/OutOfStock' : product.variants.filter(item => !item.isInStock).length >= 2 ? 'https://schema.org/LimitedAvailability' : 'https://schema.org/InStock'} />
      </div>
    </div>;
};